import {Button, Dialog, Intent} from '@blueprintjs/core';
import * as React from 'react';

import {useUserInfoPrx} from '../../store/userInfo';
import {getClasses} from '../../utils/css';
import {useIdempotenceKey} from '../../utils/idempotencyKey';

import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

const classes = getClasses({
  dialog: {
    width: 300,
  },
  button: {
    width: 70,
    height: 40,
  },
  inside: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'flex-end',
  },
  submit: {
    marginTop: 15,
    width: 60,
  },
});

export const DeleteUser: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const {deleteUser} = useUserInfoPrx();

  const [ctx] = useCancelContext();
  const idempotencyKey = useIdempotenceKey([]);
  const [deleteUserResponse, doDeleteUser] = useRequest(
    () => {
      return deleteUser(idempotencyKey);
    },
    [deleteUser, idempotencyKey],
    ctx,
  );
  return (
    <>
      <Button className={classes.button} onClick={handleOpen}>
        Delete user
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Delete user"
        onClose={handleClose}
        className={classes.dialog}
      >
        <div className={classes.inside}>
          Are you sure you want to delete this user? This cannot be undone.
          <Button
            className={classes.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doDeleteUser}
          >
            Confirm
          </Button>
        </div>
        <ResponseErrorToast response={deleteUserResponse} />
      </Dialog>
    </>
  );
};

import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useAnalyticsPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Analytics',
    proxyClass: Gazebo.Analytics.ServicePrx,
  });

import {Tab, Tabs} from '@blueprintjs/core';
import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';

import {ClubsProvider} from '../../store/clubs';
import {TournamentsProvider} from '../../store/tournaments';
import {getClasses} from '../../utils/css';

import {AllTablesManagement} from './allTables';
import {AllTournamentsManagement} from './allTournaments';
import {Layout} from './layout';
import {Reports} from './reports';
import {TablesManagement} from './tables';
import {TournamentsManagement} from './tournaments';
import {UserBalanceChangeForm} from './UserBalanceChangeForm';

const classes = getClasses({
  tabs: {
    marginTop: '5%',
    marginLeft: '20%',
  },
});

export const Admin: React.FunctionComponent = () => {
  const match = useRouteMatch<{areaId: string}>('/:areaId');
  return (
    <Layout>
      <ClubsProvider>
        <Tabs className={classes.tabs}>
          <Tab
            panel={<UserBalanceChangeForm />}
            id="balances"
            title="User Balances"
          />
          <Tab panel={<TablesManagement />} id="tables" title="Tables" />
          <Tab
            panel={
              <TournamentsProvider areaId={match?.params.areaId}>
                <TournamentsManagement />
              </TournamentsProvider>
            }
            id="tournaments"
            title="Tournaments"
          />
          <Tab
            panel={<AllTablesManagement />}
            id="all_tables"
            title="All tables"
          />
          <Tab
            panel={<AllTournamentsManagement />}
            id="all_tournaments"
            title="All tournaments"
          />
          <Tab panel={<Reports />} id="reports" title="Reports" />
        </Tabs>
      </ClubsProvider>
    </Layout>
  );
};

import {Alignment, Button, Colors, Icon, Navbar} from '@blueprintjs/core';
import {Classes} from '@blueprintjs/core';
import * as React from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useAuthContext} from '../../auth';
import {getResponseData, useSubscription} from '../../ice-client-react';
import {NavigationBarMain} from '../../navigationBarMain';
import {useClub} from '../../store/clubs';
import {useLeague} from '../../store/league';
import {useUserInfoPrx} from '../../store/userInfo';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {getClasses} from '../../utils/css';
import {centsToMainUnits} from '../../utils/numbers';

import {ChangeAvatarDialog} from './ChangeAvatarDialog';
import {DeleteUser} from './DeleteUser';

const classes = getClasses({
  navbar: {
    position: 'fixed',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  navHeader: {
    flex: '0 0 auto',
    padding: '0 2em 0 0',
  },
  navItem: {
    flex: '5 5 1em',
    padding: '0 0.5em !important',
  },
  navSpacer: {
    flex: '10 10 0',
  },
  clubIdRoot: {
    flexDirection: 'column',
  },
  masterClub: {
    textAlign: 'right',
    fontSize: '50%',
    color: Colors.GREEN5,
  },
  clubRole: {
    color: Colors.GREEN5,
  },
});

export const NavigationBar: React.FunctionComponent = () => (
  <NavigationBarMain>
    <Navbar.Group align={Alignment.LEFT} className={classes.navHeader}>
      <GoBack />
    </Navbar.Group>
    <Navbar.Group className={classes.navSpacer} />
    <Header />
    <Navbar.Group className={classes.navSpacer} />
  </NavigationBarMain>
);

const GoBack = () => {
  const match = useRouteMatch<{areaId?: string; tableId?: string}>(
    '/:areaId?/:tableId?',
  );

  const history = useHistory();
  const goBack = React.useCallback(() => {
    if (match?.params.tableId != null) {
      history.push(`/${match?.params.areaId}`);
    } else if (match?.params.areaId != null) {
      history.push('/');
    }
  }, [match, history]);

  if (match == null || match?.params.areaId == null) {
    return null;
  }
  return (
    <Button className={Classes.MINIMAL} icon="arrow-left" onClick={goBack} />
  );
};

const Header = () => {
  const location = useLocation();
  const isEmpty = location.pathname === '/';
  const match = useRouteMatch<{areaId: string}>('/:areaId');
  const response = useClub(match?.params.areaId);
  const {idTokenPayload} = useAuthContext();
  const userId = idTokenPayload?.sub ?? '';
  const club = getResponseData(response);

  const {subscribeToUsers} = useUserInfoPrx();
  const subscribeToUserInfo = React.useCallback(
    (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserInfo.User>) =>
      subscribeToUsers(subscriber, [userId]),
    [subscribeToUsers, userId],
  );

  const usersSubscriptionState = useSubscription(subscribeToUserInfo);
  const userDisplayId = React.useMemo(
    () => getResponseData(usersSubscriptionState)?.[0]?.displayId,
    [usersSubscriptionState],
  );

  // Подписка на баланс фишек
  const {subscribeToUserBalances} = useWalletsAdminPrx();
  const subscribeToBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToUserBalances(subscriber, [userId]),
    [subscribeToUserBalances, userId],
  );
  const balancesSubscriptionState = useSubscription(subscribeToBalances);
  const globalLobbyBalances = getResponseData(balancesSubscriptionState);

  // Подписка на баланс кристалликов
  const {subscribeToUserGemBalances} = useWalletsAdminPrx();
  const subscribeToGems = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToUserGemBalances(subscriber, [userId]),
    [subscribeToUserGemBalances, userId],
  );
  const gemsSubscriptionState = useSubscription(subscribeToGems);
  const gemsBalances = getResponseData(gemsSubscriptionState);

  const isInGlobalLobby = match?.params.areaId === 'global';
  const league = useLeague(club?.leagueId);
  const gems = gemsBalances?.[0]?.balance.toNumber();

  if (isEmpty || (!isInGlobalLobby && club == null)) {
    return (
      <Navbar.Group align={Alignment.CENTER} className={classes.navItem}>
        {idTokenPayload?.nickname ?? ''}
        <Navbar.Divider />
        {gems}
        <Icon size={18} icon="symbol-diamond" style={{marginLeft: 5}} />
        <Navbar.Divider />
        <ChangeAvatarDialog />
        <DeleteUser />
        <Navbar.Divider />
        <Navbar.Heading>Test client</Navbar.Heading>
      </Navbar.Group>
    );
  }
  const heading = isInGlobalLobby ? 'Global lobby' : club?.name;
  const clubDisplayId = isInGlobalLobby ? '' : `#${club?.displayId}`;
  const globalLobbyBalance = globalLobbyBalances?.[0]?.balance.toNumber();
  const balance = centsToMainUnits(
    (isInGlobalLobby ? globalLobbyBalance : club?.balance?.toNumber()) ?? 0,
  );

  return (
    <Navbar.Group align={Alignment.CENTER} className={classes.navItem}>
      {`${idTokenPayload?.nickname ?? ''} (${userDisplayId ?? '?'})`}
      <Navbar.Divider />
      {gems}
      <Icon size={18} icon="symbol-diamond" style={{marginLeft: 5}} />
      <Navbar.Divider />
      {club?.isMyClub && (
        <Navbar.Group align={Alignment.CENTER} className={classes.clubRole}>
          {club.clubId === league?.ownerClubId ? 'LH' : 'CH'}
          <Navbar.Divider />
        </Navbar.Group>
      )}
      <Navbar.Heading>{heading}</Navbar.Heading>
      <Navbar.Divider />
      <div className={classes.clubIdRoot}>
        <div>{clubDisplayId}</div>
        <div className={classes.masterClub}>
          {league != null && club?.clubId === league.ownerClubId
            ? 'Master club'
            : null}
        </div>
      </div>
      <Navbar.Divider />
      {balance}
      <Icon size={12} icon="dollar" style={{marginLeft: 5}} />
    </Navbar.Group>
  );
};

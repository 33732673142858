import {Button, InputGroup} from '@blueprintjs/core';
import {DateRange, DateRangeInput, TimePrecision} from '@blueprintjs/datetime';
import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useRequest} from 'src/utils/useRequest';

import {getResponseData, getResponseError} from '../../ice-client-react';
import {useAnalyticsPrx} from '../../store/analytics';
import {useLeaguesPrx} from '../../store/league';
import {create} from '../../utils/create';
import {getClasses} from '../../utils/css';
import {
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

const classes = getClasses({
  root: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '30px',
    margin: '20px',
  },
  getReport: {
    display: 'flex',
    $nest: {
      '& > *': {
        margin: '30px',
      },
    },
  },
  date: {
    display: 'flex',
    marginTop: '250px',
    $nest: {
      '& > *': {
        margin: '5px',
      },
    },
  },
});

const checkDates = (start: Date | null, finish: Date | null) => {
  if (start == null || finish == null) {
    throw new Error('Incorrect dates');
  }
};

const checkEmail = (email: string) => {
  if (email == '') {
    throw new Error('Fill email');
  }
};

export const Reports: React.FunctionComponent = () => {
  const [ctx] = useCancelContext();

  const {sendRakeReport, sendClubStatisticsReport} = useAnalyticsPrx();
  const {findLeagueByIdentity} = useLeaguesPrx();

  const [onEmailChanged, email] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [onLeagueIdentityChanged, leagueIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [onClubIdentityChanged, clubIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  // По умолчанию - с 1970 по текущий момент
  const [dates, setDates] = React.useState<DateRange>([
    new Date(1),
    new Date(),
  ]);
  const handleDatesChange = React.useCallback(
    (newDates: DateRange) => {
      setDates(newDates);
    },
    [setDates],
  );

  const [findLeagueResponse, doFindLeague] = useRequest(
    async () =>
      await findLeagueByIdentity(
        isNaN(+leagueIdentity.rawValue)
          ? create(Gazebo.Leagues.UUID, {leagueId: leagueIdentity.rawValue})
          : create(Gazebo.Leagues.DisplayId, {
              displayId: leagueIdentity.rawValue,
            }),
      ),
    [leagueIdentity.rawValue, findLeagueByIdentity],
    ctx,
  );

  const [getRakeReportResponse, doGetRakeReport] = useRequest(
    async () => {
      const start = dates[0];
      const finish = dates[1];
      checkDates(start, finish);
      checkEmail(email.rawValue);
      if (leagueIdentity.rawValue == '') {
        throw new Error('Fill league identity');
      }
      await doFindLeague();
      const leagueData = getResponseData(findLeagueResponse);
      if (!(leagueData instanceof Gazebo.Leagues.League)) {
        throw new Error('Incorrect league Identity');
      }

      const findLeagueError = getResponseError(findLeagueResponse);

      if (findLeagueError) {
        throw new Error('Cannot find league');
      }
      return await sendRakeReport(
        [email.rawValue],
        create(Gazebo.Analytics.FixedTimeSpan, {
          fromMs: new Ice.Long(start!.getTime()),
          toMs: new Ice.Long(finish!.getTime()),
        }),
        leagueData.leagueId,
      );
    },
    [
      dates,
      doFindLeague,
      email,
      sendRakeReport,
      findLeagueResponse,
      leagueIdentity.rawValue,
    ],
    ctx,
  );

  const [getClubReportResponse, doGetClubReport] = useRequest(
    async () => {
      const start = dates[0];
      const finish = dates[1];
      checkDates(start, finish);
      checkEmail(email.rawValue);
      if (clubIdentity.rawValue == '') {
        throw new Error('Fill club identity');
      }

      return await sendClubStatisticsReport(
        clubIdentity.rawValue,
        [email.rawValue],
        create(Gazebo.Analytics.FixedTimeSpan, {
          fromMs: new Ice.Long(start!.getTime()),
          toMs: new Ice.Long(finish!.getTime()),
        }),
      );
    },
    [dates, email, sendClubStatisticsReport, clubIdentity.rawValue],
    ctx,
  );

  return (
    <div className={classes.root}>
      <div className={classes.date}>
        <DateRangeInput
          formatDate={(date) => moment(date).format('YYYY-MM-DD HH:mm:ss')}
          parseDate={(str) => moment(str, 'YYYY-MM-DD HH:mm:ss').toDate()}
          closeOnSelection={false}
          onChange={handleDatesChange}
          placeholder={'YYYY-MM-DD HH:mm (moment)'}
          timePickerProps={{
            precision: TimePrecision.MINUTE,
            showArrowButtons: true,
          }}
        />
      </div>
      <InputGroup
        value={email.rawValue}
        id="text-input"
        onChange={onEmailChanged}
        placeholder="email"
      />
      <div className={classes.buttons}>
        <div>
          <InputGroup
            value={leagueIdentity.rawValue}
            id="text-input"
            onChange={onLeagueIdentityChanged}
            placeholder="League ID or display ID"
          />
          <div className={classes.getReport}>
            <Button
              text={'Get rake report'}
              onClick={doGetRakeReport}
              disabled={dates[0] == null || dates[1] == null}
              loading={
                getRakeReportResponse?.type === 'started' ||
                findLeagueResponse?.type === 'started'
              }
            />
          </div>
        </div>
        <div>
          <InputGroup
            value={clubIdentity.rawValue}
            id="text-input"
            onChange={onClubIdentityChanged}
            placeholder="Club ID"
          />
          <div className={classes.getReport}>
            <Button
              text={'Get club report'}
              onClick={doGetClubReport}
              disabled={dates[0] == null || dates[1] == null}
              loading={getClubReportResponse?.type === 'started'}
            />
          </div>
        </div>
      </div>
      <ResponseErrorToast response={getRakeReportResponse} />
      <ResponseErrorToast response={getClubReportResponse} />
      <ResponseErrorToast response={findLeagueResponse} />
    </div>
  );
};

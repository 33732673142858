import {
  Button,
  Card,
  Classes,
  Code,
  Collapse,
  H4,
  H5,
  Icon,
  Tab,
  Tabs,
  Text,
} from '@blueprintjs/core';
import moment from 'moment';
import React, {memo, useMemo, useState} from 'react';

import {Gazebo} from '@slices/Gazebo';

import {getResponseData} from '../../../ice-client-react';
import {useTournamentState} from '../../../store/tournament';
import {getClasses} from '../../../utils/css';
import {centsToMainUnits} from '../../../utils/numbers';
import {tablesClasses} from '../../Admin/tables';

const classes = getClasses({
  tournamentStatesRoot: {
    $nest: {
      [`& .${Classes.TAB_LIST}`]: {
        marginLeft: 10,
      },
    },
  },
  state: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: 5,
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px',
    $nest: {
      ['> *']: {
        marginTop: 5,
      },
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  code: {
    whiteSpace: 'pre',
    width: '100%',
  },
});

export const TournamentStates = () => (
  <div className={classes.tournamentStatesRoot}>
    <Tabs>
      <Tab id={'general'} title={'General'} panel={<General />} />
      <Tab
        id={'participants'}
        title={'Participants'}
        panel={<Participants />}
      />
      <Tab id={'tablesState'} title={'Tables'} panel={<TablesState />} />
      <Tab id={'prizes'} title={'Prize Distribution'} panel={<Prizes />} />
      <Tab
        id={'requirements'}
        title={'Command Requirements'}
        panel={<CommandRequirements />}
      />
      <Tab id={'hero'} title={'Hero'} panel={<HeroParticipation />} />
      <Tab id={'blindLevel'} title={'Blind Level'} panel={<BlindLevel />} />
      <Tab id={'lifecycle'} title={'Lifecycle'} panel={<Lifecycle />} />
    </Tabs>
  </div>
);

const General: React.FunctionComponent = () => {
  const {general} = useTournamentState();

  const generalData =
    useMemo<Gazebo.Tournaments.AbstractGeneralTournamentState | null>(
      () => general.data?.[0] ?? null,
      [general.data],
    );

  if (generalData == null) {
    return <div className={classes.state}>No state</div>;
  }

  return (
    <div className={classes.content}>
      <RawStateWrapper data={generalData} />
      {generalData instanceof Gazebo.Tournaments.MTT.GeneralTournamentState && (
        <div className={classes.fields}>
          <span>
            <Icon icon={'time'} />
            {`Start time: ${moment(generalData.startAtMs.toNumber()).format(
              'DD.MM.YYYY HH:mm',
            )}`}
          </span>
          <span>
            <Icon icon={'stopwatch'} />
            {`Blind level duration: ${moment
              .duration(generalData.blindLevelDurationMs.toNumber())
              .humanize()}`}
          </span>
          <span>
            <Icon icon="log-in" />
            {`Buy-in: ${centsToMainUnits(generalData.buyIn.toNumber())}`}
          </span>
          <span>
            <Icon icon="bank-account" />
            {`Prize fund: ${centsToMainUnits(
              generalData.prizeFund.toNumber(),
            )}`}
          </span>
          <span>
            <Icon icon="dollar" />
            {`Initial participant stack: ${centsToMainUnits(
              generalData.initialParticipantStack.toNumber(),
            )}`}
          </span>
        </div>
      )}
    </div>
  );
};

const RawStateWrapper = memo(
  ({
    className,
    data,
    isCollapsed: defaultState = true,
  }: {
    className?: string;
    isCollapsed?: boolean;
    data: any;
  }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultState);
    return (
      <div className={className}>
        <Button onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? 'Show' : 'Hide'} state JSON
        </Button>
        <Collapse isOpen={!isCollapsed}>
          <div className={classes.state}>
            <Code className={classes.code}>
              {JSON.stringify(data, null, 2)}
            </Code>
          </div>
        </Collapse>
      </div>
    );
  },
);

type Tables = Gazebo.Tournaments.MTT.TournamentTableState[];

const TablesState: React.FunctionComponent = () => {
  const {tablesState} = useTournamentState();
  const rawTables = getResponseData(tablesState);

  const tables = React.useMemo(() => {
    const tournamentTables = new Array<[string, Tables]>();

    if (rawTables && rawTables.length) {
      const tournamentId = [...rawTables.keys()][0];
      tournamentTables.push([
        tournamentId.toString(),
        [...rawTables.values()]
          .map((tableState) => {
            if (
              tableState instanceof Gazebo.Tournaments.MTT.TournamentTableState
            ) {
              return tableState;
            }
          })
          .filter((table) => table != null)
          .sort((a, b) => a!.index.localeCompare(b!.index)) as Tables,
      ]);
    }
    return tournamentTables;
  }, [rawTables]);

  return (
    <div className={tablesClasses.root}>
      {tables.map(([limit, tables]) => (
        <TournamentTablesSwimlane key={limit} limit={limit} tables={tables} />
      ))}
    </div>
  );
};

const TournamentTablesSwimlane: React.FunctionComponent<{
  limit: string;
  tables: Tables;
}> = ({limit, tables}) => {
  return (
    <div>
      <H5 className={tablesClasses.swimlaneHeader}>{limit}</H5>
      <div className={tablesClasses.swimlaneContent}>
        {tables.map((table) => (
          <TournamentTableCard key={table.tableId} table={table} />
        ))}
      </div>
    </div>
  );
};

const TournamentTableCard: React.FunctionComponent<{
  table: Gazebo.Tournaments.MTT.TournamentTableState;
}> = ({table}) => {
  return (
    <Card className={tablesClasses.tableCard}>
      <H5 className={tablesClasses.tableCardHeader}>{table.tableId}</H5>
      <span>
        <Icon icon="info-sign" />
        Index: {table.index}
      </span>
      <span>
        <Icon icon="user" />
        PlayersCount: {table.playersCount}
      </span>
      <span>
        <Icon icon="dollar" />
        MinStack: {table.minStack?.toNumber().toString()}
      </span>
      <span>
        <Icon icon="dollar" />
        AverageStack: {table.averageStack?.toNumber().toString()}
      </span>
      <span>
        <Icon icon="dollar" />
        MaxStack: {table.maxStack?.toNumber().toString()}
      </span>
    </Card>
  );
};

const Participants: React.FunctionComponent = () => {
  const {participants} = useTournamentState();

  const participantsData = useMemo(
    () => participants.data ?? [],
    [participants.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={participantsData} />
      <div className={classes.fields}>
        <span>
          <Icon icon={'people'} />
          {`Participants count: ${participantsData.length}`}
        </span>
      </div>
    </div>
  );
};

const Prizes: React.FunctionComponent = () => {
  const {prizeDistribution} = useTournamentState();

  const prizesData = useMemo(
    () => prizeDistribution.data ?? [],
    [prizeDistribution.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={prizesData} isCollapsed={false} />
    </div>
  );
};

const CommandRequirements: React.FunctionComponent = () => {
  const {commandRequirements} = useTournamentState();

  const commandRequirementsData = useMemo(
    () => commandRequirements.data ?? [],
    [commandRequirements.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={commandRequirementsData} isCollapsed={false} />
    </div>
  );
};

const HeroParticipation: React.FunctionComponent = () => {
  const {heroParticipation} = useTournamentState();

  const heroParticipationData = useMemo(
    () => heroParticipation.data ?? [],
    [heroParticipation.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={heroParticipationData} isCollapsed={false} />
    </div>
  );
};

const BlindLevel: React.FunctionComponent = () => {
  const {blindLevel} = useTournamentState();

  const blindLevelData = useMemo(
    () => blindLevel.data ?? [],
    [blindLevel.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={blindLevelData} isCollapsed={false} />
    </div>
  );
};

type Lifecycle =
  | {
      type: 'InProgress' | 'Unexpected';
    }
  | {
      type: 'Finished';
      disappearAt?: Date;
    }
  | {
      type: 'AwaitingStart';
      startAtMs?: Date;
    }
  | {
      type: 'ScheduledBreak';
      start: Date;
      end: Date;
    }
  | {
      type: 'Cancelled';
      reason: 'CancelledManually' | 'NotEnoughPlayers' | 'Unexpected';
    };

const lifecycleFromIce = (
  lifecycle: Gazebo.Tournaments.AbstractTournamentLifecycleState,
): Lifecycle => {
  return lifecycle instanceof Gazebo.Tournaments.AwaitingStart
    ? {
        type: 'AwaitingStart',
        startAtMs:
          lifecycle.startAtMs && new Date(lifecycle.startAtMs.toNumber()),
      }
    : lifecycle instanceof Gazebo.Tournaments.InProgress
    ? {type: 'InProgress'}
    : lifecycle instanceof Gazebo.Tournaments.Finished
    ? {
        type: 'Finished',
        disappearAt:
          lifecycle.disappearAt && new Date(lifecycle.disappearAt.toNumber()),
      }
    : lifecycle instanceof Gazebo.Tournaments.ScheduledBreak
    ? {
        type: 'ScheduledBreak',
        start: new Date(lifecycle.startAtMs.toNumber()),
        end: new Date(lifecycle.endAtMs.toNumber()),
      }
    : lifecycle instanceof Gazebo.Tournaments.Cancelled
    ? {
        type: 'Cancelled',
        reason:
          lifecycle.cancellationReason instanceof
          Gazebo.Tournaments.CancelledManually
            ? 'CancelledManually'
            : lifecycle.cancellationReason instanceof
              Gazebo.Tournaments.NotEnoughPlayers
            ? 'NotEnoughPlayers'
            : 'Unexpected',
      }
    : {type: 'Unexpected'};
};

const Lifecycle: React.FunctionComponent = () => {
  const {lifecycle} = useTournamentState();

  const lifecycleData: Lifecycle[] = useMemo(
    () => (lifecycle.data ?? []).map(lifecycleFromIce),
    [lifecycle.data],
  );

  return (
    <div className={classes.state}>
      <RawStateWrapper data={lifecycleData} isCollapsed={false} />
    </div>
  );
};
